var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"font-weight-medium text-h6 mb-3"},[_vm._v(_vm._s(_vm.$i18n.t("users.title")))]),_c('v-card',{staticClass:"rounded-md mb-4"},[_c('div',{staticClass:"d-flex align-center justify-start px-4 py-3 flex-wrap"},[_c('v-btn',{staticClass:"mr-2",attrs:{"depressed":"","loading":_vm.loading.refresh},on:{"click":function($event){return _vm.refreshItems()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-refresh")]),_vm._v(" "+_vm._s(_vm.$i18n.t("btn.refresh"))+" ")],1),_c('v-btn',{attrs:{"depressed":"","loading":_vm.loading.filter},on:{"click":function($event){return _vm.filterItems()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-filter-variant")]),_vm._v(" "+_vm._s(_vm.$i18n.t("btn.filter"))+" ")],1),(_vm.$permission.$can('user.create', ['user']))?_c('div',{staticClass:"ml-auto"},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.addUser()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-account-plus-outline")]),_c('span',[_vm._v(_vm._s(_vm.$i18n.t("btn.add")))])],1)],1):_vm._e()],1)]),_c('v-card',[_c('v-data-table',{attrs:{"loading":_vm.loading.list,"headers":_vm.headers,"items":_vm.items,"options":_vm.pagination,"footer-props":_vm.footerProps,"items-per-page":_vm.itemPerPage,"sort-by":"id","sort-desc":""},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([(!_vm._.isEmpty(_vm.items))?{key:"body",fn:function(ref){
var items = ref.items;
return [_c('tbody',[_vm._l((items),function(item){return [_c('v-hover',{key:item.id,scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('tr',[_c('td',[_c('span',[_vm._v(" "+_vm._s(_vm.$moment(item.createdAt).format("YYYY-MM-DD HH:mm:ss"))+" ")])]),_c('td',[_c('span',[_vm._v(" "+_vm._s(item.lastLogin ? _vm.$moment(item.lastLogin).format("YYYY-MM-DD HH:mm:ss") : "-")+" ")])]),_c('td',[_c('span',[_vm._v(_vm._s(item.username))])]),_c('td',[(item.status === 'enabled')?_c('v-chip',{staticClass:"table-chips",attrs:{"color":"success"}},[_c('span',[_vm._v(_vm._s(_vm.$i18n.t("users.fields.status.enabled")))])]):(item.status === 'disabled')?_c('v-chip',{staticClass:"table-chips",attrs:{"color":"danger","dark":""}},[_c('span',[_vm._v(_vm._s(_vm.$i18n.t("users.fields.status.disabled")))])]):_c('span',[_vm._v("-")])],1),_c('td',[_c('v-chip',{staticClass:"table-chips"},[_c('span',[_vm._v(_vm._s(item.roles.join(",")))])])],1),_c('td',{staticStyle:{"min-width":"150px"}},[(_vm.$permission.$can('user.get-service', ['user']))?[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(hover || _vm.$vuetify.breakpoint.mdAndDown),expression:"hover || $vuetify.breakpoint.mdAndDown"}],staticClass:"mr-2",attrs:{"x-small":"","icon":""},on:{"click":function($event){return _vm.affectToUser(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-domain")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$i18n.t("users.btn.affect_to_service")))])])]:_vm._e(),(_vm.$permission.$can('user.update', ['user']))?[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(hover || _vm.$vuetify.breakpoint.mdAndDown),expression:"hover || $vuetify.breakpoint.mdAndDown"}],attrs:{"x-small":"","icon":""},on:{"click":function($event){return _vm.editUser(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-pencil-outline")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$i18n.t("users.btn.edit")))])])]:_vm._e()],2)])]}}],null,true)})]})],2)]}}:null],null,true)})],1),(_vm.$permission.$can('user.create', ['user']) || _vm.$permission.$can('user.update', ['user']))?_c('user-form',{attrs:{"form":_vm.form,"dialog":_vm.dialog.form},on:{"success":_vm.saveUserSuccess}}):_vm._e(),_c('user-filter',{attrs:{"configuration":_vm.filter.user,"dialog":_vm.dialog.filter},on:{"filter":_vm.applyFilter}}),(_vm.$permission.$can('user.get-service', ['user']))?_c('user-service',{attrs:{"dialog":_vm.dialogService,"user":_vm.item}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }