<template>
  <v-dialog v-model="dialog.display" max-width="500">
    <v-card>
      <v-card-title>
        <span class="secondary-font">{{ $t("users.service.title") }}</span>
        <v-btn class="ml-auto" icon @click="dialog.display = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="pt-2 mb-0">
        <v-text-field
          readonly
          dense
          :value="user.username"
          :label="$t('users.fields.username.title')"
          hide-details
        ></v-text-field>
      </v-card-text>
      <v-card-text>
        <template v-if="!loading.service">
          <v-list class="pt-0">
            <v-list-item v-for="service in services" class="px-0" :key="`content-${service.id}`">
              <v-list-item-content class="font-weight-bold pt-2">
                <v-list-item-title>
                  {{
                  service.service.name
                  }}
                </v-list-item-title>
                <v-list-item-subtitle class="text-body-2 font-weight-bold">
                  {{
                  service.roles.join(",")
                  }}
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <div class="d-flex align-center">
                  <template v-if="$permission.$can('user.update-service', ['user'])">
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          icon
                          v-bind="attrs"
                          v-on="on"
                          color="primary"
                          small
                          @click="editService(service)"
                        >
                          <v-icon>mdi-pencil-outline</v-icon>
                        </v-btn>
                      </template>
                      <span>{{ $t("btn.edit") }}</span>
                    </v-tooltip>
                  </template>
                  <template v-if="$permission.$can('user.delete-service', ['user'])">
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          icon
                          v-bind="attrs"
                          v-on="on"
                          @click="deleteService(service)"
                          :loading="loading.delete && item.id === service.id"
                          color="danger"
                          small
                        >
                          <v-icon>mdi-delete-outline</v-icon>
                        </v-btn>
                      </template>
                      <span>{{ $t("btn.delete") }}</span>
                    </v-tooltip>
                  </template>
                </div>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </template>
        <template v-else>
          <div class="text-center mt-2 mb-3">
            <v-progress-circular indeterminate color="secondary" width="4" size="40"></v-progress-circular>
          </div>
        </template>
      </v-card-text>
      <v-card-actions class="mt-0 pt-0" v-if="$permission.$can('user.add-service', ['user'])">
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="addService()">
          <v-icon left>mdi-domain-plus</v-icon>
          {{ $t("users.service.btn.add") }}
        </v-btn>
      </v-card-actions>
    </v-card>
    <user-service-form
      @success="successSaveServiceForm()"
      :dialog="dialogForm"
      :form="form"
      :service="service"
      v-if="$permission.$can('user.add-service', ['user'])"
    ></user-service-form>
  </v-dialog>
</template>

<script>
import UserServiceForm from "./service-form";
import { mapActions } from "vuex";
export default {
  props: {
    dialog: {
      type: Object,
      default: function() {
        return {};
      }
    },
    user: {
      tyoe: Object,
      default: function() {
        return {};
      }
    }
  },
  data: () => ({
    form: {
      id: null,
      userId: null,
      roles: [],
      serviceIds: []
    },
    services: [],
    loading: {
      service: false
    },
    service: {},
    dialogForm: { display: false, title: null }
  }),
  methods: {
    ...mapActions({ request: "request", notify: "notification/notify" }),
    async getServices() {
      this.services = [];
      this.loading.service = true;
      try {
        const response = await this.request({
          url: `users.getServices?userId=${this.user.id}`,
          messages: { 500: true }
        });
        this.services = response.data;
      } catch (error) {
        //empty
      }
      this.loading.service = false;
    },
    addService() {
      for (const prop in this.form) {
        if (prop === "userId") {
          this.form["userId"] = this.user.id;
          continue;
        }
        this.form[prop] = null;
      }
      this.dialogForm = {
        display: true,
        title: this.$t("users.service.add.title"),
        success: this.$t("users.service.add.success")
      };
    },
    editService(service) {
      this.service = service.service;
      this.form.roles = service.roles;
      this.form.id = service.id;
      this.form.serviceIds = [service.service.id];
      this.form.userId = this.user.id;
      this.dialogForm = {
        display: true,
        title: this.$t("users.service.edit.title"),
        success: this.$t("users.service.edit.success")
      };
    },
    successSaveServiceForm() {
      this.dialogForm.display = false;
      this.getServices();
    },
    deleteService() {}
  },
  watch: {
    "dialog.display"(val) {
      if (val) {
        this.getServices();
      }
    }
  },
  components: {
    UserServiceForm
  }
};
</script>