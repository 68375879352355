<template>
  <div>
    <div class="font-weight-medium text-h6 mb-3">{{ $i18n.t("users.title") }}</div>
    <v-card class="rounded-md mb-4">
      <div class="d-flex align-center justify-start px-4 py-3 flex-wrap">
        <v-btn depressed @click="refreshItems()" :loading="loading.refresh" class="mr-2">
          <v-icon left>mdi-refresh</v-icon>
          {{ $i18n.t("btn.refresh") }}
        </v-btn>
        <v-btn depressed @click="filterItems()" :loading="loading.filter">
          <v-icon left>mdi-filter-variant</v-icon>
          {{ $i18n.t("btn.filter") }}
        </v-btn>
        <div class="ml-auto" v-if="$permission.$can('user.create', ['user'])">
          <v-btn color="primary" @click="addUser()">
            <v-icon left>mdi-account-plus-outline</v-icon>
            <span>{{ $i18n.t("btn.add") }}</span>
          </v-btn>
        </div>
      </div>
    </v-card>
    <v-card>
      <v-data-table
        :loading="loading.list"
        :headers="headers"
        :items="items"
        :options.sync="pagination"
        :footer-props="footerProps"
        :items-per-page="itemPerPage"
        sort-by="id"
        sort-desc
      >
        <template v-slot:body="{ items }" v-if="!_.isEmpty(items)">
          <tbody>
            <template v-for="item in items">
              <v-hover :key="item.id" v-slot="{ hover }">
                <tr>
                  <td>
                    <span>
                      {{
                      $moment(item.createdAt).format("YYYY-MM-DD HH:mm:ss")
                      }}
                    </span>
                  </td>
                  <td>
                    <span>
                      {{
                      item.lastLogin
                      ? $moment(item.lastLogin).format("YYYY-MM-DD HH:mm:ss")
                      : "-"
                      }}
                    </span>
                  </td>
                  <td>
                    <span>{{ item.username }}</span>
                  </td>
                  <td>
                    <v-chip v-if="item.status === 'enabled'" class="table-chips" color="success">
                      <span>{{ $i18n.t("users.fields.status.enabled") }}</span>
                    </v-chip>
                    <v-chip
                      v-else-if="item.status === 'disabled'"
                      class="table-chips"
                      color="danger"
                      dark
                    >
                      <span>{{ $i18n.t("users.fields.status.disabled") }}</span>
                    </v-chip>
                    <span v-else>-</span>
                  </td>
                  <td>
                    <v-chip class="table-chips">
                      <span>{{ item.roles.join(",") }}</span>
                    </v-chip>
                  </td>
                  <td style="min-width: 150px">
                    <template v-if="$permission.$can('user.get-service', ['user'])">
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            @click="affectToUser(item)"
                            v-show="hover || $vuetify.breakpoint.mdAndDown"
                            x-small
                            icon
                            v-bind="attrs"
                            v-on="on"
                            class="mr-2"
                          >
                            <v-icon color="primary">mdi-domain</v-icon>
                          </v-btn>
                        </template>
                        <span>{{ $i18n.t("users.btn.affect_to_service") }}</span>
                      </v-tooltip>
                    </template>
                    <template v-if="$permission.$can('user.update', ['user'])">
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            @click="editUser(item)"
                            v-show="hover || $vuetify.breakpoint.mdAndDown"
                            x-small
                            icon
                            v-bind="attrs"
                            v-on="on"
                          >
                            <v-icon color="primary">mdi-pencil-outline</v-icon>
                          </v-btn>
                        </template>
                        <span>{{ $i18n.t("users.btn.edit") }}</span>
                      </v-tooltip>
                    </template>
                  </td>
                </tr>
              </v-hover>
            </template>
          </tbody>
        </template>
      </v-data-table>
    </v-card>
    <user-form
      v-if="$permission.$can('user.create', ['user']) || $permission.$can('user.update', ['user'])"
      :form="form"
      @success="saveUserSuccess"
      :dialog="dialog.form"
    ></user-form>
    <user-filter @filter="applyFilter" :configuration="filter.user" :dialog="dialog.filter"></user-filter>
    <user-service v-if="$permission.$can('user.get-service', ['user'])" :dialog="dialogService" :user="item"></user-service>
  </div>
</template>

<script>
import UserService from "./service";
import UserFilter from "./../commons/filter";
import UserForm from "./form";
import ListMixin from "./../../mixins/list";

export default {
  mixins: [ListMixin],
  created() {
    this.setFilterUser();
  },
  data: () => ({
    path: "users.list",
    form: {
      userId: null,
      username: null,
      status: null,
      roles: [],
      "profile.firstName": null,
      "profile.lastName": null,
      "profile.email": null,
      "profile.phone": null,
      password: null,
      confirmPassword: null
    },
    filter: {
      user: []
    },
    item: {},
    filters: {},
    dialogService: {
      display: false
    }
  }),
  methods: {
    setHeaders() {
      this.headers = [
        {
          text: this.$i18n.t("users.fields.created.title"),
          align: "start",
          sortable: true,
          value: "createdAt",
          width: 160
        },
        {
          text: this.$i18n.t("users.fields.last_login.title"),
          align: "start",
          sortable: true,
          value: "lastLogin",
          width: 160
        },
        {
          text: this.$i18n.t("users.fields.username.title"),
          align: "start",
          sortable: true,
          value: "username"
        },
        {
          text: this.$i18n.t("users.fields.status.title"),
          align: "start",
          sortable: false,
          value: "status",
          width: 80
        },
        {
          text: this.$i18n.t("users.fields.role.title"),
          align: "start",
          sortable: false,
          value: "roles"
        },
        {
          text: "",
          align: "start",
          sortable: false,
          value: null,
          width: 120
        }
      ];
    },
    affectToUser(item) {
      this.item = item;
      this.dialogService.display = true;
    },
    addUser() {
      this.$utils.setPropertyValue(this.dialog, "form", {
        title: this.$i18n.t("users.add.title"),
        display: true,
        success: this.$i18n.t("users.add.success"),
        update: false
      });
    },
    editUser(item) {
      for (const prop in this.form) {
        if (prop === "userId") {
          this.form.userId = item.id;
        } else {
          this.form[prop] = this.$utils.getPropertyValue(item, prop);
        }
      }
      this.$utils.setPropertyValue(this.dialog, "form", {
        title: this.$i18n.t("users.edit.title"),
        display: true,
        success: this.$i18n.t("users.edit.success"),
        update: true
      });
    },
    saveUserSuccess() {
      this.refreshItems();
      this.dialog.form.display = false;
      if (this.dialog.form.update) return;
      this.resetForm();
    },
    resetForm() {
      for (const prop in this.form) {
        if (prop === "roles") {
          this.form[prop] = [];
        } else {
          this.form[prop] = null;
        }
      }
    },
    setFilterUser() {
      this.filter.user = [
        {
          name: "username",
          type: {
            name: "VTextField",
            operator: "$contL",
            options: {
              label: this.$i18n.t("users.fields.username.title"),
              placeholder: this.$i18n.t("users.fields.username.placeholder"),
              clearable: true
            }
          }
        }
      ];
    },
    filterItems() {
      this.dialog.filter.display = true;
      this.dialog.filter.title = this.$i18n.t("users.filter.title");
    }
  },
  components: {
    UserForm,
    UserFilter,
    UserService
  }
};
</script>