<template>
  <v-dialog v-model="dialog.display" :max-width="600">
    <v-card>
      <v-card-title>
        <span class="secondary-font">{{ dialog.title }}</span>
        <v-btn class="ml-auto" icon @click="dialog.display = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="pt-2">
        <v-text-field
          :label="$i18n.t('users.fields.username.title')"
          :placeholder="$i18n.t('users.fields.username.placeholder')"
          v-model="form.username"
          autocomplete="off"
          @input="delayTouch($v.form.username)"
          @blur="$v.form.username.$touch"
          :error-messages="usernameErrors"
        ></v-text-field>
        <v-autocomplete
          :loading="loading.role"
          :label="$i18n.t('users.fields.role.title')"
          :placeholder="$i18n.t('users.fields.role.placeholder')"
          v-model="form.roles"
          multiple
          item-value="name"
          item-text="name"
          :items="rolesItems"
          :search-input.sync="search.role"
          @input="delayTouch($v.form.roles)"
          @blur="$v.form.roles.$touch"
          :error-messages="rolesErrors"
          cache-items
          chips
          small-chips
          hide-no-data
          hide-selected
        ></v-autocomplete>
        <v-select
          :label="$i18n.t('users.fields.status.title')"
          :placeholder="$i18n.t('users.fields.status.placeholder')"
          :items="userStatuses"
          @input="delayTouch($v.form.status)"
          @blur="$v.form.status.$touch"
          :error-messages="statusErrors"
          v-model="form.status"
        ></v-select>
        <v-row>
          <v-col sm="6" cols="12" class="py-0">
            <v-text-field
              :label="$i18n.t('users.fields.first_name.title')"
              :placeholder="$i18n.t('users.fields.first_name.placeholder')"
              v-model="form['profile.firstName']"
              class="mr-2"
              autocomplete="off"
              @input="delayTouch($v.form['profile.firstName'])"
              @blur="$v.form['profile.firstName'].$touch"
              :error-messages="firstNameErrors"
            ></v-text-field>
          </v-col>
          <v-col sm="6" cols="12" class="py-0">
            <v-text-field
              class="ml-2"
              :label="$i18n.t('users.fields.last_name.title')"
              :placeholder="$i18n.t('users.fields.last_name.placeholder')"
              v-model="form['profile.lastName']"
              autocomplete="off"
              @input="delayTouch($v.form['profile.lastName'])"
              @blur="$v.form['profile.lastName'].$touch"
              :error-messages="lastNameErrors"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col sm="6" cols="12" class="py-0">
            <v-text-field
              :label="$i18n.t('users.fields.email.title')"
              :placeholder="$i18n.t('users.fields.email.placeholder')"
              v-model="form['profile.email']"
              autocomplete="off"
              class="mr-2"
              @input="delayTouch($v.form['profile.email'])"
              @blur="$v.form['profile.email'].$touch"
              :error-messages="emailErrors"
            ></v-text-field>
          </v-col>
          <v-col sm="6" cols="12" class="py-0">
            <v-text-field
              class="ml-2"
              :label="$i18n.t('users.fields.phone.title')"
              :placeholder="$i18n.t('users.fields.phone.placeholder')"
              v-model="form['profile.phone']"
              @input="delayTouch($v.form['profile.phone'])"
              @blur="$v.form['profile.phone'].$touch"
              autocomplete="off"
              :error-messages="phoneErrors"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="6" class="py-0">
            <v-text-field
              class="mr-2"
              :label="$i18n.t('users.fields.password.title')"
              :placeholder="$i18n.t('users.fields.password.placeholder')"
              v-model="form.password"
              type="password"
              autocomplete="off"
              @input="delayTouch($v.form.password)"
              @blur="$v.form.password.$touch"
              :error-messages="passwordErrors"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" class="py-0">
            <v-text-field
              class="ml-2"
              :label="$i18n.t('users.fields.confirm_password.title')"
              :placeholder="$i18n.t('users.fields.confirm_password.placeholder')"
              v-model="form.confirmPassword"
              type="password"
              autocomplete="off"
              @input="delayTouch($v.form.confirmPassword)"
              @blur="$v.form.confirmPassword.$touch"
              :error-messages="confirmPasswordErrors"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="dialog.display = false">{{ $i18n.t('btn.close') }}</v-btn>
        <v-btn
          text
          color="primary"
          :loading="loading.save"
          @click.prevent="save()"
        >{{ $i18n.t('btn.save') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {
  required,
  minLength,
  maxLength,
  helpers,
  email,
  sameAs
} from "vuelidate/lib/validators";
import { mapGetters } from "vuex";
import FormDelayTouchMixin from "./../../mixins/form-delay-touch";
import FormMixin from "./../../mixins/form";

export default {
  mixins: [FormDelayTouchMixin, FormMixin],
  props: {
    dialog: {
      type: Object,
      default: function() {}
    },
    form: {
      type: Object,
      default: function() {
        return {};
      }
    }
  },
  validations() {
    const validations = {
      form: {
        username: {
          required,
          minLength: minLength(5),
          maxLength: maxLength(50)
        },
        roles: {
          required
        },
        status: {
          required,
          matchStatus: value =>
            !helpers.req(value) || /^(enabled|disabled)$/.test(value)
        },
        "profile.firstName": {
          required,
          maxLength: maxLength(100)
        },
        "profile.lastName": {
          required,
          maxLength: maxLength(100)
        },
        "profile.email": {
          email,
          maxLength: maxLength(100)
        },
        "profile.phone": {
          matchPhone: value =>
            !helpers.req(value) || /^(\+)?[0-9]{0,20}$/.test(value)
        },
        password: {
          minLength: minLength(5),
          maxLength: maxLength(50)
        },
        confirmPassword: {
          sameAsPassword: sameAs("password")
        }
      }
    };

    if (!this.dialog.update) {
      validations.form.password.required = required;
    }

    return validations;
  },
  data: () => ({
    loading: {
      role: false,
      save: false
    },
    search: {
      role: null
    },
    rolesItems: []
  }),
  methods: {
    getPath() {
      return !this.dialog.update ? "users.create" : "users.update";
    },
    getMessages() {
      return {
        200: () => {
          return this.dialog.success;
        },
        201: () => {
          return this.dialog.success;
        },
        403: true,
        404: true,
        400: true,
        422: error => {
          const self = this;
          if (error.code === "E100") {
            return self.$i18n.t("users.errors.E100");
          }
        }
      };
    }
  },
  watch: {
    async "search.role"(val) {
      if (!val) return;
      if (val.length < 3) return;

      this.rolesItems = (
        await this.request({
          url: `roles.list?filter=scope||$eq||user&filter=name||$contL||${val}`
        })
      ).data.data;
    },
    "dialog.display"(val) {
      if (val && this.dialog.update) {
        this.rolesItems = [];
        for (const role of this.form.roles) {
          this.rolesItems.push({ name: role });
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      userStatuses: "user/statuses"
    }),
    usernameErrors() {
      const errors = [];
      if (!this.$v.form.username.$dirty) return errors;

      if (!this.$v.form.username.required)
        errors.push(this.$i18n.t("users.fields.username.required"));
      if (!this.$v.form.username.minLength)
        errors.push(
          this.$i18n.t("users.fields.username.min_length", {
            min: this.$v.form.username.$params.minLength.min
          })
        );
      if (!this.$v.form.username.maxLength)
        errors.push(
          this.$i18n.t("users.fields.username.max_length", {
            max: this.$v.form.username.$params.maxLength.max
          })
        );
      return errors;
    },
    statusErrors() {
      const errors = [];
      if (!this.$v.form.status.$dirty) return errors;

      if (!this.$v.form.status.required)
        errors.push(this.$i18n.t("users.fields.status.required"));
      if (!this.$v.form.status.matchStatus)
        errors.push(this.$i18n.t("users.fields.status.invalid"));
      return errors;
    },
    rolesErrors() {
      const errors = [];
      if (!this.$v.form.roles.$dirty) return errors;

      if (!this.$v.form.roles.required)
        errors.push(this.$i18n.t("users.fields.role.required"));
      return errors;
    },
    firstNameErrors() {
      const errors = [];
      if (!this.$v.form["profile.firstName"].$dirty) return errors;

      if (!this.$v.form["profile.firstName"].required)
        errors.push(this.$i18n.t("users.fields.first_name.required"));
      if (!this.$v.form["profile.firstName"].maxLength)
        errors.push(
          this.$i18n.t("users.fields.first_name.max_length", {
            max: this.$v.form["profile.firstName"].$params.maxLength.max
          })
        );
      return errors;
    },
    lastNameErrors() {
      const errors = [];
      if (!this.$v.form["profile.lastName"].$dirty) return errors;

      if (!this.$v.form["profile.lastName"].required)
        errors.push(this.$i18n.t("users.fields.last_name.required"));
      if (!this.$v.form["profile.lastName"].maxLength)
        errors.push(
          this.$i18n.t("users.fields.last_name.max_length", {
            max: this.$v.form["profile.lastName"].$params.maxLength.max
          })
        );
      return errors;
    },
    emailErrors() {
      const errors = [];
      if (!this.$v.form["profile.email"].$dirty) return errors;

      if (!this.$v.form["profile.email"].email)
        errors.push(this.$i18n.t("users.fields.email.invalid"));
      if (!this.$v.form["profile.email"].maxLength)
        errors.push(this.$i18n.t("users.fields.email.invalid"));
      return errors;
    },
    phoneErrors() {
      const errors = [];
      if (!this.$v.form["profile.phone"].$dirty) return errors;

      if (!this.$v.form["profile.phone"].matchPhone)
        errors.push(this.$i18n.t("users.fields.phone.invalid"));
      return errors;
    },
    passwordErrors() {
      const errors = [];
      if (!this.$v.form.password.$dirty) return errors;

      if (this.$utils.hasProperty(this.$v.form.password, "required")) {
        if (!this.$v.form.password.required)
          errors.push(this.$i18n.t("users.fields.password.required"));
      }

      if (!this.$v.form.password.minLength)
        errors.push(
          this.$i18n.t("users.fields.password.min_length", {
            min: this.$v.form.password.$params.minLength.min
          })
        );

      if (!this.$v.form.password.maxLength)
        errors.push(
          this.$i18n.t("users.fields.password.max_length", {
            min: this.$v.form.password.$params.maxLength.max
          })
        );
      return errors;
    },
    confirmPasswordErrors() {
      const errors = [];
      if (!this.$v.form.confirmPassword.$dirty) return errors;

      if (!this.$v.form.confirmPassword.sameAsPassword)
        errors.push(this.$i18n.t("users.fields.confirm_password.invalid"));
      return errors;
    }
  }
};
</script>