<template>
  <v-dialog max-width="450" v-model="dialog.display">
    <v-card>
      <v-card-title>
        <span class="secondary-font">{{ dialog.title }}</span>
        <v-btn class="ml-auto" icon @click="dialog.display = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="pt-2">
        <v-autocomplete
          :items="items.services"
          item-text="name"
          item-value="id"
          v-model="form.serviceIds"
          :label="$t('users.service.fields.service.title')"
          :placeholder="$t('users.service.fields.service.placeholder')"
          :search-input.sync="search.service"
          @input="delayTouch($v.form.serviceIds)"
          @blur="$v.form.serviceIds.$touch"
          :error-messages="serviceIdsErrors"
          cache-items
          multiple
          chips
          small-chips
          hide-no-data
          hide-selected
          :disabled="form.id ? true : false"
        ></v-autocomplete>
        <v-autocomplete
          :items="items.roles"
          item-text="name"
          item-value="name"
          v-model="form.roles"
          :label="$t('users.service.fields.role.title')"
          :placeholder="$t('users.service.fields.role.placeholder')"
          :search-input.sync="search.role"
          @input="delayTouch($v.form.roles)"
          @blur="$v.form.roles.$touch"
          :error-messages="rolesErrors"
          multiple
          cache-items
          chips
          small-chips
          hide-no-data
          hide-selected
        ></v-autocomplete>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="dialog.display = false">{{
          $i18n.t("btn.close")
        }}</v-btn>
        <v-btn
          text
          color="primary"
          :loading="loading.save"
          @click.prevent="save()"
          >{{ $i18n.t("btn.save") }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import { mapActions } from "vuex";
import FormDelayTouchMixin from "./../../mixins/form-delay-touch";
import FormMixin from "./../../mixins/form";
export default {
  mixins: [FormDelayTouchMixin, FormMixin],
  props: {
    dialog: {
      type: Object,
      default: function () {
        return {};
      },
    },
    form: {
      type: Object,
      default: function () {
        return {};
      },
    },
    service: {
      type: Object,
    },
  },
  data: () => ({
    items: {
      services: [],
      roles: [],
    },
    search: {
      service: null,
      role: null,
    },
  }),
  validations() {
    const validations = { form: { roles: { required }, serviceIds: {} } };
    if (!this.form.id) {
      validations.form.serviceIds.required = required;
    }
    return validations;
  },
  methods: {
    ...mapActions({ request: "request" }),
    getPath() {
      return !this.form.id ? "users.addServices" : "users.updateServices";
    },
    getMessages() {
      const self = this;
      return {
        200: this.dialog.success,
        201: this.dialog.success,
        403: true,
        400: true,
        500: true,
        404: this.$t("users.errors.404"),
        422: (error) => {
          if (error.code === "E100") {
            return self.$t("rate-plans.errors.404");
          } else if (error.code === "E101") {
            return self.$t("rate-plans.errors.E101");
          } else if (error.code === "E102") {
            return self.$t("rate-plans.channel-pricing.errors.E102");
          }
        },
      };
    },
    getData(form) {
      const { id, userId, ...service } = form;
      if (this.form.id) {
        return { roles: service.roles, serviceUserId: id };
      } else {
        return { ...service, userId: userId };
      }
    },
    preSubmit() {
      this.loading.save = true;
    },
    postSubmit() {
      this.loading.save = false;
    },
    resetForm() {
      this.$v.form.$reset();
    },
  },
  watch: {
    async "search.service"(val) {
      if (!val) return;
      if (val.length < 3) return;

      this.items.services = (
        await this.request({
          url: `services.list?filter=name||$contL||${val}`,
        })
      ).data.data;
    },
    async "search.role"(val) {
      if (!val) return;
      if (val.length < 3) return;

      this.items.roles = (
        await this.request({
          url: `roles.list?filter=scope||$eq||service&filter=name||$contL||${val}`,
        })
      ).data.data;
    },
    "dialog.display"(val) {
      if (val && this.form.id) {
        this.items.roles = [];
        this.items.services = [];
        for (const role of this.form.roles) {
          this.items.roles.push({ name: role });
        }
        if (this.service) {
          this.items.services.push({
            name: this.service.name,
            id: this.service.id,
          });
        }
      }
    },
  },
  computed: {
    serviceIdsErrors() {
      const errors = [];

      if (!this.$v.form.serviceIds.$dirty) return errors;
      if (
        this.$utils.hasProperty(this.$v.form.serviceIds, "required") &&
        !this.$v.form.serviceIds.required
      )
        errors.push(this.$i18n.t("users.service.fields.service.required"));
      return errors;
    },
    rolesErrors() {
      const errors = [];

      if (!this.$v.form.roles.$dirty) return errors;
      if (!this.$v.form.roles.required)
        errors.push(this.$i18n.t("users.service.fields.role.required"));
      return errors;
    },
  },
};
</script>